import React from "react";
import Modal from "react-modal";
import bookPhoto from "../products/bookbg.jpg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement("#booking");

export default function AppModal() {
  //   let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  //     function afterOpenModal() {
  //   //     // references are now sync'd and can be accessed.
  //       subtitle.style.color = "#f00";
  //     }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "5mm",
          margin: "5mm",
        }}
      >
        <div
          onClick={openModal}
          style={{
            borderStyle: "solid",
            margin: "20mm",
            display: "flex",
            color: "#c8b562",
            paddingLeft: "20px",
            paddingRight: "20px",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontFamily: "Montserrat",
              fontSize: "21px",
              fontWeight: "bold ",
            }}
          >
            book now
          </p>
        </div>
        <Modal
          isOpen={modalIsOpen}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <iframe
            id="frameBooking"
            src="https://na02.envisiongo.com/a/SALONSAPPHIREBEAUTY/OnlineBookingFrame.aspx"
            width="95%"
            height="400mm"
            title="Book"
            frameborder="0"
            allowTransparency="true"
          ></iframe>
        </Modal>
      </div>
    </div>
  );
}
