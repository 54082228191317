import React from "react";
import logo from "../nobglogo.png";
import { BsList } from "react-icons/bs";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import styled from "styled-components";
import NavBarModal from "./NavBarModal";
import AnchorLink from "react-anchor-link-smooth-scroll";

const StyledH3 = styled.h2`
  font-family: "Montserrat", sans-serif;
  margin-left: 2mm;
`;

const AppNavDrawer = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: "5px",
          marginTop: "15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <BsList
          fontSize={35}
          style={{ marginTop: "5px", color: "gold" }}
          onClick={toggleDrawer}
        />
      </div>

      <Drawer
        open={isOpen}
        onClose={toggleDrawer}
        direction="right"
        className="bla bla bla"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#fffffa",
          }}
        >
          <img
            src={logo}
            style={{ height: "125px", width: "125px" }}
            alt="logo"
          />
        </div>
        <StyledH3>
          <AnchorLink
            style={{ textDecoration: "none", color: "black" }}
            href="#careers"
          >
            Careers
          </AnchorLink>
        </StyledH3>
        <StyledH3>
          <AnchorLink
            style={{ textDecoration: "none", color: "black" }}
            href="#policies"
          >
            Policies
          </AnchorLink>
        </StyledH3>
        <StyledH3>
          <AnchorLink
            style={{ textDecoration: "none", color: "black" }}
            href="#services"
          >
            Services
          </AnchorLink>
        </StyledH3>
        {/* <StyledH3>
          <AnchorLink
            style={{ textDecoration: "none", color: "black" }}
            href="#aboutus"
          >
            About Us
          </AnchorLink>
        </StyledH3> */}

        <NavBarModal />
      </Drawer>
    </>
  );
};

export default AppNavDrawer;
