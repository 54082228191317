import styled from "styled-components";

const SalonAddress = () => {
  return (
    <>
      <p
        style={{
          fontFamily: "Montserrat",
          fontSize: "26px",
          fontWeight: "bold",
          color: "#c8b562",
          marginBottom: "-5mm",
        }}
      >
        209 S Burleson
      </p>

      <p
        style={{
          fontFamily: "Montserrat",
          fontSize: "25px",
          fontWeight: "bold",
          color: "#c8b562",
        }}
      >
        Giddings
      </p>
      <p
        style={{
          fontFamily: "Montserrat",
          fontSize: "24px",
          fontWeight: "bold",
          color: "#c8b562",

          marginBottom: "20px",
        }}
      >
        979-716-5595
      </p>
    </>
  );
};

export default SalonAddress;
