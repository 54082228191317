import React from "react";
import { Route, Link } from "react-router-dom";
import Apply from "../routes/Apply";

const Careers = () => {
  const [isShadowingVisible, setIsShadowingVisible] = React.useState(false);
  const [isAssociatesVisible, setIsAssociatesVisible] = React.useState(false);
  const [isTeamVisible, setIsTeamVisible] = React.useState(false);

  return (
    <>
      <div
        style={{
          padding: "2mm",
          margin: "2mm",
          borderRadius: "2%",
          backgroundColor: "#F7ECE1",
        }}
      >
        <p
          style={{
            fontSize: "21px",
            marginBottom: "5px",
            backgroundColor: "#F7ECE1",
            alignItems: "center",
          }}
        >
          Here at Salon Sapphire,
        </p>{" "}
        we are an education and team based salon. Providing a positive, safe and
        fun place to work! Becoming educated in the skills you need in order to
        grow your career is something we strive to create for you from the
        start!{" "}
        <p
          style={{
            fontSize: "21px",
            marginBottom: "5px",
            backgroundColor: "#F7ECE1",
            alignItems: "center",
          }}
        >
          Whether you are just starting your career or a seasoned stylist, we
          would love to have you!
        </p>
        <p
          style={{
            fontFamily: "Montserrat",
            fontSize: "22px",
            fontWeight: "bold",
            color: "black",
          }}
        >
          What we have for you:
        </p>
        <div style={{ alignItems: "center", justifyItems: "center" }}>
          <div
            style={{
              minHeight: "30mm",
              padding: "5px",
              minWidth: "100%",
              borderRadius: "10px",
              backgroundColor: "#F7ECE1",
            }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "-5mm",
              }}
            >
              <p
                onClick={() => setIsShadowingVisible(!isShadowingVisible)}
                style={{
                  color: "#2c4569",
                  fontFamily: "Poiret",
                  fontSize: "27px",
                }}
              >
                Shadowing
              </p>
            </span>
            {isShadowingVisible ? (
              <div>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                  }}
                >
                  See what it would be like to work at Salon Sapphire!
                </p>
                <p
                  style={{
                    alignItems: "center",
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                  }}
                >
                  Thinking about joining the team? Want to know what we are all
                  about? Come and spend some time with us on the floor! We would
                  love to meet you and show you what Salon Sapphire is all
                  about!
                </p>
              </div>
            ) : null}
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "-5mm",
              }}
            >
              <p
                onClick={() => setIsAssociatesVisible(!isAssociatesVisible)}
                style={{
                  color: "#2c4569",
                  fontFamily: "Poiret",
                  fontSize: "27px",
                }}
              >
                Associates Program
              </p>
            </span>
            {isAssociatesVisible ? (
              <p
                style={{
                  alignItems: "center",
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                }}
              >
                Grow your experience, knowledge, and clientele! Our associates
                program is a 6 month immersive, paid program! You will be
                partnered with one of our master-experienced stylist; learning
                and working right alongside that stylist on their clientele. As
                you move along in the program you will earn up to 3 opportunity
                days to be behind the chair, and work on your own guests. This
                will give you the opportunity to start applying your advanced
                training behind your chair, as well as ensure when you graduate
                the program you will already have clients in your chair! Think
                of this program as your master’s education!
              </p>
            ) : null}
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "-5mm",
              }}
            >
              <p
                onClick={() => setIsTeamVisible(!isTeamVisible)}
                style={{
                  color: "#2c4569",
                  fontFamily: "Poiret",
                  fontSize: "27px",
                }}
              >
                Our Stylist Team
              </p>
            </span>
            {isTeamVisible ? (
              <div>
                <p
                  tyle={{
                    alignItems: "center",
                    fontFamily: "Poiret",
                    fontWeight: "bold",
                  }}
                >
                  It’s career time!
                </p>
                <p
                  tyle={{
                    alignItems: "center",
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                  }}
                >
                  Let’s continue growing and shaping your career as a stylist!
                  At Salon Sapphire, we strive to grow each individual stylist
                  in a way that will best suit their needs. Continuing your
                  education and having a passion and desire for team and
                  personal growth is something we value here. We realize each
                  stylist is unique, and growth happens differently for each
                  person,but as long as your willingness to achieve your goals
                  and make progress, we support you every step of the way. We
                  look forward to working with you!
                </p>
              </div>
            ) : null}
          </div>
        </div>
        <a style={{ textDecoration: "none" }} href="apply">
          <div
            style={{
              borderStyle: "solid",
              margin: "10mm",
              display: "flex",
              color: "#2c4569",
              paddingLeft: "18px",
              paddingRight: "18px",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                color: "#2c4569",
                fontFamily: "Montserrat",
                fontSize: "25px",
                fontWeight: "bold",
              }}
            >
              apply now
            </p>
          </div>
        </a>
      </div>
    </>
  );
};

export default Careers;
