import "../App.css";
import logo from "../logo-white.png";
import ashton from "../ashtonCareers.jpg";
import Careers from "../components/Careers";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AppModal from "../components/AppModal";
import AppNavBar from "../components/AppNavBar";
import SocialMedia from "../components/SocialMedia";
import redkenLine from "../products/redkenLine.jpg";
import { useState, useRef, Linking } from "react";
import SalonAddress from "../components/SalonAddress";
import redkenWhite from "../products/redkenWhite.jpg";
import salonimg1 from "../salon-images/salonimg1.jpg";
import salonimg2 from "../salon-images/salonimg2.jpg";
import salonimg3 from "../salon-images/salonimg3.jpg";
import salonimg4 from "../salon-images/salonimg4.jpg";
import salonchairs from "../salon-images/salonchairs.jpg";
import redkenPurple from "../products/redkenPurple.jpg";
import brightProductRack from "../products/brightProductRack.jpg";

function Home() {
  const [isColorVisible, setIsColorVisible] = useState(false);
  const [isCutVisible, setIsCutVisible] = useState(false);
  const [isTreatmentsVisible, setIsTreatmentsVisible] = useState(false);
  const [isWaxingVisible, setIsWaxingVisible] = useState(false);
  const [isStylingVisible, setIsStylingVisible] = useState(false);
  const [isExtensionsVisible, setIsExtensionsVisible] = useState(false);
  const [isPoliciesVisible, setIsPoliciesVisible] = useState(false);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  let toggleColor = () => setIsColorVisible(!isColorVisible);
  let toggleCut = () => setIsCutVisible(!isCutVisible);
  let toggleStyling = () => setIsStylingVisible(!isStylingVisible);
  let toggleTreatments = () => setIsTreatmentsVisible(!isTreatmentsVisible);
  let toggleWaxing = () => setIsWaxingVisible(!isWaxingVisible);
  let toggleExtensions = () => setIsExtensionsVisible(!isExtensionsVisible);

  return (
    <div
      style={{
        backgroundColor: "#f6f5f0",
        overflow: "hidden",
      }}
    >
      <AppNavBar />
      <div
        style={{
          height: windowSize.current[1],
          width: windowSize.current[0],
          backgroundColor: "#2c4569",
          paddingBottom: "10mm",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "-15mm",
          }}
        >
          <img style={{ width: "350px" }} src={`${logo}`} />
        </span>
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              fontSize: "17px",
              color: "#c8b562",
              fontFamily: "Montserrat",
              fontWeight: "bolder",
              maxWidth: "98%",
            }}
          >
            We are here to make you feel gorgeous.
          </p>
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <a
            style={{
              textDecoration: "none",
              color: "#c8b562",
              fontFamily: "Montserrat",
              fontSize: "26px",
            }}
            href="https://www.google.com/maps/dir//209+S+Burleson+St,+Giddings,+TX+78942/@30.1820373,-97.0213213,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x864465cc8357c85d:0xa03b34b51baf6c29!2m2!1d-96.9388307!2d30.1820465?entry=ttu"
          >
            <p
              style={{
                fontFamily: "Heebo",
                fontSize: "26px",
                color: "#c8b562",
                marginBottom: "-5mm",
                fontWeight: "bold",
              }}
            >
              209 S Burleson
            </p>
          </a>
          <p
            style={{
              fontFamily: "Heebo",
              fontSize: "26px",
              color: "#c8b562",
              fontWeight: "bold",
            }}
          >
            Giddings Tx
          </p>
          <p
            style={{
              fontFamily: "Montserrat",
              fontSize: "26px",
              color: "#c8b562",
              fontWeight: "bold",
              marginBottom: "-10mm",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                color: "#c8b562",
                fontFamily: "Montserrat",
                fontSize: "26px",
              }}
              href="tel:9797165595"
            >
              979-716-5595
            </a>
          </p>
        </div>

        <AppModal />
      </div>
      <div
        style={{
          margin: "3mm",
          borderRadius: "2%",
          marginBottom: "10mm",
        }}
      >
        <Carousel responsive={responsive} autoPlay={true} infinite={true}>
          <div>
            <img style={{ height: "150mm", width: "150mm" }} src={salonimg2} />
          </div>
          <div>
            {" "}
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={salonimg1}
            ></img>
          </div>
          <div>
            {" "}
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={salonchairs}
            ></img>
          </div>
          <div>
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={salonimg3}
            ></img>
          </div>
          <div>
            {" "}
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={salonimg4}
            ></img>
          </div>
        </Carousel>
      </div>

      <div id="booking" style={{ position: "absolute", color: "black" }}>
        <p
          style={{
            fontSize: "27px",
            fontFamily: "Montserrat",
            fontWeight: "bold",
          }}
        ></p>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h2
          id="services"
          style={{
            fontFamily: "Montserrat",
            fontSize: "36px",
          }}
        >
          Services
        </h2>
        <hr
          style={{
            borderWidth: "1px",
            borderBottomColor: "gold",
            width: "20mm",
            marginBottom: "5mm",
          }}
        />
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            fontFamily: "Heebo",
            justifyContent: "center",
            minWidth: "100mm",
          }}
        >
          <p>learn about our </p>
          <p
            id="policies"
            style={{ textDecoration: "underline", textIndent: "1mm" }}
            onClick={() => setIsPoliciesVisible(!isPoliciesVisible)}
          >
            policies
          </p>
        </span>
        {isPoliciesVisible ? (
          <>
            <p style={{ marginBottom: "-10mm" }}>Cancelation Policy:</p>
            <p
              style={{
                padding: "10mm",
                fontFamily: "Montserrat",
                fontWeight: "bold",
                marginBottom: "-10mm",
              }}
            >
              We understand that sometimes things happen, however, we want to
              ensure our stylist’s time is valued and respected. If you are
              canceling your appointment here at Salon Sapphire, please cancel
              before 24 hours. If cancelation happens outside the required time
              allotted for cancelation notice, there will be a 50% charge of the
              canceled appointment booked. This charge will be due at your next
              reservation. We appreciate our clients so much, and understand
              that things can happen.
            </p>
            <p
              style={{
                padding: "10mm",
                fontFamily: "Montserrat",
                fontWeight: "bold",
              }}
            >
              Please be respectful and understanding when it comes to canceling
              or rescheduling here at Salon Sapphire. We love getting to see you
              in our salon and can’t wait to service you!
            </p>
          </>
        ) : null}
      </div>

      <div
        style={{
          display: "flex",
          backgroundColor: "#eae0d5",
          flexDirection: "column",
          alignItems: "center",
          margin: "2mm",
          borderRadius: "10px",
        }}
      >
        <p
          onClick={toggleColor}
          style={{
            fontFamily: "Poiret",
            fontSize: "24px",
            color: "black",
            fontWeight: "bold",
          }}
        >
          Color +
        </p>
        <hr
          style={{
            borderWidth: "1px",
            borderBottomColor: "gold",
            width: "20mm",
            marginBottom: "7mm",
          }}
        />
        {isColorVisible ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "Heebo",
                fontWeight: "bold",
                fontSize: "21px",
                marginBottom: "-3",
              }}
            >
              Partial Foil: $86-$116+
            </p>
            <p
              style={{
                fontFamily: "Heebo",
                fontSize: "21px",
                fontWeight: "bold",
              }}
            >
              Full Foil: $116-146+
            </p>
            <p
              style={{
                fontFamily: "Heebo",
                fontWeight: "bold",
                fontSize: "21px",
              }}
            >
              Balayage/Ombre: $136-$181+
            </p>
            <p
              style={{
                fontFamily: "Heebo",
                fontWeight: "bold",
                fontSize: "21px",
              }}
            >
              Specialty Color: $136-$181+
            </p>
            <p
              style={{
                fontFamily: "Heebo",
                fontWeight: "bold",
                fontSize: "21px",
              }}
            >
              Mega Foil: $146-176+
            </p>
            <p
              style={{
                fontFamily: "Heebo",
                fontWeight: "bold",
                fontSize: "21px",
              }}
            >
              Shades EQ Glossing: $36-$66+
            </p>
            <p
              style={{
                fontFamily: "Heebo",
                fontWeight: "bold",
                fontSize: "21px",
              }}
            >
              Single All Over Color: $56-$80+
            </p>
            <p
              style={{
                fontFamily: "Heebo",
                fontWeight: "bold",
                fontSize: "21px",
              }}
            >
              Platinum Blonding/Card $200+ (Consult)
            </p>
          </div>
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            onClick={toggleCut}
            style={{
              fontFamily: "Poiret",
              fontSize: "24px",
              alignSelf: "center",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Cut +
          </p>
          <hr
            style={{
              borderWidth: "1px",
              borderBottomColor: "gold",
              width: "20mm",
              marginBottom: "7mm",
            }}
          />
          {isCutVisible ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Barber Cut $26-$56+
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Cut and Style $36-$66+
              </p>

              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Bang/Beard Trim $10-$15+
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Child's Haircut 8 years & under $26-$56+
              </p>
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            onClick={toggleStyling}
            style={{
              color: "black",
              alignSelf: "center",
              fontSize: "24px",
              fontWeight: "bold",
              fontFamily: "Poiret",
            }}
          >
            Styling +
          </p>
          <hr
            style={{
              borderWidth: "1px",
              borderBottomColor: "gold",
              width: "20mm",
              marginBottom: "5mm",
            }}
          />
          {isStylingVisible ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Curl/Flat Iron $15+
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Bridal Party Hair $85+
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Bride Hair $81-$111+
              </p>{" "}
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Special Occasion $61-$91+
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Shampoo Blow Dry/Style $26-$56+
              </p>
            </div>
          ) : null}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            onClick={toggleWaxing}
            style={{
              fontFamily: "Poiret",
              fontSize: "24px",
              alignSelf: "center",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Waxing +
          </p>
          <hr
            style={{
              borderWidth: "1px",
              borderBottomColor: "gold",
              width: "20mm",
              marginBottom: "10mm",
            }}
          />
          {isWaxingVisible ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Brow $15
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Lip $7
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Chin $10
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Full Face $40
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Brow Tint $15
              </p>
            </div>
          ) : null}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <p
            onClick={toggleTreatments}
            style={{
              display: "flex",
              alignSelf: "center",
              justifySelf: "center",
              fontFamily: "Poiret",
              fontSize: "24px",
              color: "black",
              fontWeight: "bold",
            }}
          >
            Treatments +
          </p>
          <hr
            style={{
              borderWidth: "1px",
              borderBottomColor: "gold",
              width: "20mm",
              marginBottom: "10mm",
            }}
          />
          {isTreatmentsVisible ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                ABC $20
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Pre-Art $15
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                }}
              >
                Hair Mask $10-$20
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                  marginBottom: "-2mm",
                }}
              >
                Brazilian Blowout $250+
              </p>
              <p style={{ fontSize: "10px" }}>
                A smoothing treatment that helps eliminate frizz, repairs
                seasonal damage, protects against humidity, cuts styling time in
                half, and improves hair health and manageability. Lasts up to 12
                weeks
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                  marginBottom: "-2mm",
                }}
              >
                Brazilian Blowout Express $95+
              </p>
              <p style={{ fontSize: "10px" }}>
                Removes impurities, while delivering smooth, silky results and
                helps cut down on style time. Dramatically reduces frizz while
                repairing and protecting the hair. Lasts up to 4 weeks.
              </p>
              <p
                style={{
                  fontFamily: "Heebo",
                  fontWeight: "bold",
                  fontSize: "21px",
                  marginBottom: "-2mm",
                }}
              >
                Brazilian Split End Mender $40+
              </p>
              <p style={{ fontSize: "10px" }}>
                This dual action treatment instantly repairs existing damage
                while also helping to prevent any damage in the future. Lasts up
                to 4 weeks
              </p>
            </div>
          ) : null}
        </div>
        <div
          style={{
            flexDirection: "column",
            display: "flex",
          }}
        >
          <p
            onClick={toggleExtensions}
            style={{
              fontFamily: "Poiret",
              fontSize: "24px",
              color: "black",
              fontWeight: "bold",
              alignSelf: "center",
              justifySelf: "center",
            }}
          >
            Extensions +
          </p>
          <hr
            style={{
              borderWidth: "1px",
              borderBottomColor: "gold",
              width: "25mm",
              marginBottom: "5mm",
            }}
          />
          {isExtensionsVisible ? (
            <>
              <div
                style={{
                  display: "flex",
                  margin: "1mm",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "15px",
                    marginBottom: "-5px",
                    fontFamily: " Montserrat",
                    fontWeight: "bold",
                  }}
                >
                  Extension Consultation
                </p>
                <p style={{ fontSize: "13px", padding: "3mm" }}>
                  To book an extension service you must book a consultation
                  prior to application appointment. We will determine which
                  extension type will work best for you and we will go over
                  pricing and maintenance.{" "}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  K-Tips
                </p>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  I-Tips
                </p>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  Hand Tied Wefts
                </p>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  Volume Wefts
                </p>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  Flex Wefts
                </p>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  Infiniti Wefts
                </p>
                <p
                  style={{
                    fontFamily: "Heebo",
                    fontWeight: "bold",
                    fontSize: "21px",
                  }}
                >
                  Tape-Ins
                </p>
              </div>
            </>
          ) : null}
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2 style={{ fontFamily: "Montserrat", fontSize: "36px" }}>Products</h2>
      </div>
      <div
        style={{
          margin: "1mm",
          borderRadius: "2%",
          marginBottom: "10mm",
        }}
      >
        <Carousel responsive={responsive} autoPlay={true} infinite={true}>
          <div>
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={brightProductRack}
            />
          </div>
          <div>
            {" "}
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={redkenLine}
            ></img>
          </div>
          <div>
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={redkenWhite}
            ></img>
          </div>
          <div>
            {" "}
            <img
              style={{ height: "150mm", width: "150mm" }}
              src={redkenPurple}
            ></img>
          </div>
        </Carousel>
        ;
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h2
          id="careers"
          style={{
            fontFamily: "Montserrat",
            fontSize: "36px",
            marginBottom: "4mm",
          }}
        >
          Careers
        </h2>{" "}
      </div>
      <hr
        style={{
          borderWidth: "1px",
          borderBottomColor: "gold",
          width: "10%",
          marginBottom: "7mm",
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "7mm",
        }}
      >
        <div
          style={{
            backgroundImage: `url(${ashton})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            height: "150mm",
            width: "150mm",
            marginLeft: "5mm",
            marginRight: "5mm",
          }}
        ></div>
      </div>
      <hr
        style={{
          borderWidth: "1px",
          borderBottomColor: "gold",
          width: "10%",
          marginBottom: "7mm",
        }}
      />
      <div
        style={{
          backgroundColor: "#eae0d5",
        }}
      >
        <Careers />
      </div>

      <div
        style={{
          height: "100mm",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          padding: "10mm",
          backgroundColor: "#2c4569",
        }}
      >
        <SalonAddress />
        <div style={{ width: "100%", marginBottom: "20mm" }}></div>
        <SocialMedia />
        <p style={{ backgroundColor: "#2c4569", color: "#c8b562" }}>
          Copyright Salon Sapphire 2024
        </p>
      </div>

      <span
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {" "}
      </span>
    </div>
  );
}

export default Home;
