import React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, FormControl } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import emailjs from "@emailjs/browser";

function Apply() {
  //personal info
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zip, setZip] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");

  //applying for
  const [position, setPosition] = useState("");
  const [desiredStartDate, setDesiredStartDate] = useState("");
  const [usCitizen, setUsCitizen] = useState("");
  const [salary, setSalary] = useState("");
  const [fulltime, setFullTime] = useState("");

  //Education History
  const [highestGrade, setHighestGrade] = useState("");
  const [graduated, setGraduated] = useState("");

  //Employment History
  const [employerOne, setEmployerOne] = useState("");
  const [employerTwo, setEmployerTwo] = useState("");

  const [startDateOne, setStartDateOne] = useState("");
  const [startDateTwo, setStartDateTwo] = useState("");
  const [previousPositionOne, setPreviousPositionOne] = useState("");
  const [previousPositionTwo, setPreviousPositionTwo] = useState("");
  const [reasonForLeavingOne, setReasonForLeavingOne] = useState("");
  const [reasonForLeavingTwo, setReasonForLeavingTwo] = useState("");

  //Personal References
  const [refNameOne, setRefNameOne] = useState("");
  const [refNameTwo, setRefNameTwo] = useState("");
  const [refNameThree, setRefNameThree] = useState("");
  const [refRelationshipOne, setRefRelationshipOne] = useState("");
  const [refRelationshipTwo, setRefRelationshipTwo] = useState("");
  const [refRelationshipThree, setRefRelationshipThree] = useState("");
  const [refPhoneOne, setRefPhoneOne] = useState("");
  const [refPhoneTwo, setRefPhoneTwo] = useState("");
  const [refPhoneThree, setRefPhoneThree] = useState("");

  const applicationData = {
    first_name: firstName,
    middly_name: middleName,
    last_name: lastName,
    zip: zip,
    phone: phone,
    city: city,
    state: state,
    address: address,
    email: email,
    position: position,
    desired_start_date: desiredStartDate,
    citizen: usCitizen,
    salary: salary,
    full_time: fulltime,
    highest_grade: highestGrade,
    graduated: graduated,
    employer_one: {
      employerOne,
      startDateOne,
      reasonForLeavingOne,
    },
    employer_two: {
      employerTwo,
      startDateTwo,
      reasonForLeavingTwo,
    },
    reference_one: {
      refNameOne,
      refRelationshipOne,
      refPhoneOne,
    },
    reference_two: {
      refNameTwo,
      refRelationshipTwo,
      refPhoneTwo,
    },
  };

  emailjs.init({
    publicKey: "2O2LXo4KbZb3RqGZz",
    blockHeadless: true,
  });

  const sendEmail = () => {
    emailjs.send("default_service", "template_z566o6s", applicationData).then(
      () => {
        console.log("SUCCESS!");
      },
      (error) => {
        console.log("FAILED...", error.text);
      }
    );
  };

  return (
    <div style={{ backgroundColor: "#f6f5f0" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginTop: "-5mm",
        }}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "-8mm",
          }}
        >
          <p
            style={{
              fontFamily: "Dancing Script",
              fontSize: "4ch",
            }}
          >
            Application for Employment
          </p>
        </span>
      </div>{" "}
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          fontSize: "20px",
          marginBottom: "-7mm",
          fontFamily: "Montserrat",
          fontWeight: "bold",
          justifyContent: "center",
        }}
      >
        <p>Salon Sapphire</p>
      </span>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          fontFamily: "Montserrat",
          fontWeight: "bold",
          justifyContent: "center",
          marginBottom: "-5mm",
        }}
      >
        <p style={{ fontSize: "13px" }}>209 S Burleson S Giddings, Tx 78942</p>
      </span>
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          fontFamily: "Montserrat",
          fontWeight: "bold",
          justifyContent: "center",
        }}
      >
        <p style={{ fontSize: "13px" }}>979-716-5595</p>
      </span>
      <div style={{ borderStyle: "solid", margin: "1mm" }}>
        <div
          style={{
            backgroundColor: "#fec3a6",
            display: "flex",
            justifyContent: "center",
            padding: "1px",
          }}
        >
          <p
            style={{
              fontSize: "15px",
              fontFamily: "Roboto Slab",
            }}
          >
            Personal Information
          </p>
        </div>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "12ch " },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="outlined-required"
              label="First"
              variant="filled"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              label="Middle"
              type="text"
              variant="filled"
              onChange={(e) => {
                setMiddleName(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              label="Last"
              type="text"
              variant="filled"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              label="Zip"
              type="text"
              variant="filled"
              onChange={(e) => {
                setZip(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              label="Phone #"
              type="text"
              variant="filled"
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              label="City"
              type="text"
              variant="filled"
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              style={{ width: "7ch" }}
              label="State"
              type="text"
              variant="filled"
              onChange={(e) => {
                setState(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              style={{ width: "17ch" }}
              label="Address"
              type="text"
              variant="filled"
              onChange={(e) => {
                setAddress(e.target.value);
              }}
            />
            <TextField
              id="outlined-disabled"
              style={{ width: "95%" }}
              label="Email"
              variant="filled"
              type="text"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </Box>
        <div style={{ borderStyle: "solid" }}>
          <div
            style={{
              backgroundColor: "#fec3a6",
              display: "flex",
              justifyContent: "center",
              padding: "1px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontFamily: "Roboto Slab",
              }}
            >
              Applying For
            </p>
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "12ch " },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-required"
                label="Position"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setPosition(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                label="Start Date"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setDesiredStartDate(e.target.value);
                }}
              />

              <TextField
                id="outlined-disabled"
                label="US Citizen?"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setUsCitizen(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                style={{ width: "95%" }}
                label="Desired Salary"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setSalary(e.target.value);
                }}
              />
              <div style={{ margin: "3mm" }}>
                <FormControlLabel
                  onChange={(e) => {
                    setFullTime(e.target.value);
                  }}
                  control={<Checkbox defaultChecked />}
                  label="Full Time"
                />
                <FormControlLabel control={<Checkbox />} label="Part Time" />
                <FormControlLabel control={<Checkbox />} label="Seasonal" />
              </div>
            </div>
          </Box>
        </div>
        <div style={{ borderStyle: "solid" }}>
          <div
            style={{
              padding: "1px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#fec3a6",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontFamily: "Roboto Slab",
              }}
            >
              Education History
            </p>
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "12ch " },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <FormControl style={{ width: "95%", margin: "2mm" }}>
                <InputLabel id="demo-simple-select-label">
                  Highest Grade Completed
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant="standard"
                  defaultValue={""}
                  onChange={(e) => {
                    setHighestGrade(e.target.value);
                  }}
                >
                  <MenuItem value={"High School"}>High School</MenuItem>
                  <MenuItem value={"Trade School"}>Trade School</MenuItem>
                  <MenuItem value={"College"}>College</MenuItem>
                  <MenuItem value={"Grad School"}>Graduate School</MenuItem>
                </Select>
              </FormControl>
              <div style={{ margin: "3mm" }}>
                <FormControlLabel control={<Checkbox />} label="Graduated" />
              </div>
            </div>
          </Box>
        </div>
        <div style={{ borderStyle: "solid" }}>
          <div
            style={{
              backgroundColor: "#fec3a6",
              display: "flex",
              justifyContent: "center",
              padding: "1px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontFamily: "Roboto Slab",
              }}
            >
              Employment History
            </p>
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "11.5ch " },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-required"
                label="Employer"
                variant="filled"
                type="text"
                style={{ width: "95%" }}
                onChange={(e) => {
                  setEmployerOne(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                label="Start Date"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setStartDateOne(e.target.value);
                }}
              />

              <TextField
                id="outlined-disabled"
                label="Position"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setPreviousPositionOne(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                style={{ width: "95%" }}
                label="Reason For Leaving"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setReasonForLeavingOne(e.target.value);
                }}
              />
              <hr />
              <TextField
                id="outlined-required"
                label="Employer"
                variant="filled"
                type="text"
                style={{ width: "95%" }}
                onChange={(e) => {
                  setEmployerTwo(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                label="Start Date"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setStartDateTwo(e.target.value);
                }}
              />

              <TextField
                id="outlined-disabled"
                label="Position"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setPreviousPositionTwo(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                style={{ width: "95%" }}
                label="Reason For Leaving"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setReasonForLeavingTwo(e.target.value);
                }}
              />
              <hr />
            </div>
          </Box>
        </div>
        <div style={{ borderStyle: "solid" }}>
          <div
            style={{
              backgroundColor: "#fec3a6",
              display: "flex",
              justifyContent: "center",
              padding: "1px",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                fontFamily: "Roboto Slab",
              }}
            >
              Personal References
            </p>
          </div>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "12ch " },
            }}
            noValidate
            autoComplete="off"
          >
            <div>
              <TextField
                id="outlined-required"
                label="Name"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setRefNameOne(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                label="Relationship"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setRefRelationshipOne(e.target.value);
                }}
              />

              <TextField
                id="outlined-disabled"
                label="Phone #"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setRefPhoneOne(e.target.value);
                }}
              />
              <hr />
              <TextField
                id="outlined-required"
                label="Name"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setRefNameTwo(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                label="Relationship"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setRefRelationshipTwo(e.target.value);
                }}
              />

              <TextField
                id="outlined-disabled"
                label="Phone #"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setRefPhoneTwo(e.target.value);
                }}
              />
              <hr />
              <TextField
                id="outlined-required"
                label="Name"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setRefNameThree(e.target.value);
                }}
              />
              <TextField
                id="outlined-disabled"
                label="Relationship"
                type="text"
                variant="filled"
                onChange={(e) => {
                  setRefRelationshipThree(e.target.value);
                }}
              />

              <TextField
                id="outlined-disabled"
                label="Phone #"
                variant="filled"
                type="text"
                onChange={(e) => {
                  setRefPhoneThree(e.target.value);
                }}
              />
            </div>
          </Box>
        </div>

        <span
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "15mm",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={sendEmail}
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#fec3a6",
              color: "black",
              fontFamily: "Montserrat",
              fontWeight: "bold",
              marginRight: "3mm",
            }}
          >
            Submit
          </Button>
          <Button
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#fec3a6",
              color: "black",
              fontFamily: "Montserrat",
              fontWeight: "bold",
              marginLeft: "3mm",
            }}
          >
            Clear
          </Button>
        </span>
      </div>
    </div>
  );
}

export default Apply;
