import AppNavDrawer from "./AppNavDrawer";
import logo from "../nobglogo.png";

const AppNavBar = () => {
  return (
    <>
      <span
        style={{
          top: "0",
          zIndex: "5",
          height: "75px",
          display: "flex",
          borderRadius: "1%",
          position: "sticky",
          overflow: "hidden",
          flexDirection: "row",
          backgroundColor: "white",
          marginBottom: "-10mm",
          boxShadow: "0 0 10px 1px rgba(0, 0, 0, 0.2)",
        }}
      >
        <span style={{}}>
          <AppNavDrawer />
        </span>
        <span
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // width: "100%",
          }}
        >
          <img
            src={logo}
            style={{ height: "110px", width: "110px" }}
            alt="logo"
          />
        </span>
      </span>
    </>
  );
};

export default AppNavBar;
