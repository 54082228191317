import React from "react";
import ReactDOM from "react-dom";
import Modal from "react-modal";
import bookPhoto from "../products/bookbg.jpg";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement("#booking");

export default function NavBarModal() {
  //   let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  //     function afterOpenModal() {
  //   //     // references are now sync'd and can be accessed.
  //       subtitle.style.color = "#f00";
  //     }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div>
      <p
        onClick={openModal}
        style={{
          color: "navy",
          position: "absolute",
          fontFamily: "Montserrat",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        Book Now
      </p>
      <Modal
        isOpen={modalIsOpen}
        //   onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <iframe
          id="frameBooking"
          src="https://na02.envisiongo.com/a/SALONSAPPHIREBEAUTY/OnlineBookingFrame.aspx"
          width="95%"
          height="400mm"
          title="Book"
          frameborder="0"
          allowTransparency="true"
        ></iframe>
      </Modal>
    </div>
  );
}
