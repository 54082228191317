import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";

const SocialMedia = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <a
        href={"https://www.facebook.com/profile.php?id=100086339367124"}
        rel="noreferrer"
        target="_blank"
      >
        <FaFacebookF
          color={"white"}
          size={"24px"}
          style={{ marginRight: "3mm" }}
        />
      </a>
      <a
        href={"https://www.instagram.com/salonsapphiregiddingstx/"}
        rel="noreferrer"
        target="_blank"
      >
        <FaInstagram
          color={"white"}
          size={"26px"}
          style={{ marginLeft: "3mm" }}
        />
      </a>
    </div>
  );
};

export default SocialMedia;

//testguess
//1234
